import { addToCart } from "@/redux/slices/cartSlice";
import { resetSelectedOffer } from "@/redux/slices/offersSlice";
import { Star } from "iconsax-react";
import Image from "next/image";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import TimeCounter from "./timeCounter";
import { currencyFormat } from "@/Utils/commons";
import { useTranslation } from "@/Utils/lanHook";

export default function OffersPopup({ onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedOffer } = useSelector((state) => state.offers || null);

  const calculatePrice = (pri1, pri2) => {
    return Number(pri1 + pri2).toFixed(2);
  };

  const handleAddToCart = () => {
    const offerDetails = {
      order_details: [
        {
          offer_id: selectedOffer?.id,
          account_product_detail_id: null,
          image: selectedOffer?.image,
          name: selectedOffer?.name,
          quantity: 1,
          unit_price: calculatePrice(
            selectedOffer?.total_price,
            selectedOffer?.taxes
          ),
          quantity_total: calculatePrice(
            selectedOffer?.total_price,
            selectedOffer?.taxes
          ),
          unit_quantity_total: calculatePrice(
            selectedOffer?.total_price,
            selectedOffer?.taxes
          ),
          order_details_options: [],
        },
      ],
    };
    console.log("Dispatching Offer:", offerDetails);
    dispatch(addToCart(offerDetails));

    handleClose();
  };

  const handleClose = () => {
    dispatch(resetSelectedOffer());
    onClose();
  };

  console.log("selectedOffer", selectedOffer);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-[90%] lg:h-[60%] rounded-3xl md:flex bg-white overflow-auto scrollbar-hide p-5">
        <Image
          src={selectedOffer?.image}
          alt="offer"
          width={1920}
          height={1080}
          className="md:w-1/2 h-1/2 lg:h-full object-fill rounded-xl"
        />
        <div className="flex-1 md:mx-4 mt-4 md:mt-0">
          <div>
            <h1 className="font-extrabold">{selectedOffer?.name}</h1>
            <p className="text-primary font-bold">
              {selectedOffer?.total_price} {currencyFormat()}
            </p>
          </div>
          <div className="flex gap-2">
            <div className="flex justify-center items-center w-fit gap-1 px-2 py-1 mt-2 text-black rounded-md bg-[#F2F2F2]">
              <Star size="15" color="black" />
              <span className="text-[13px]">
                4.4<sup className="text-[11px]"> (200)</sup>
              </span>
            </div>
            <div className="flex justify-center items-center w-fit px-2 py-1 mt-2 text-black rounded-md bg-[#F2F2F2]">
              <span className="text-[13px]">20%</span>
            </div>
          </div>
          <div className="lg:flex gap-2 border-b-2 pb-2 mb-2">
            <TimeCounter
              t={t}
              date={selectedOffer?.end_date}
              className={"w-full"}
            />
            <p className="bg-primary/20 p-2 rounded-xl text-primary text-[90%] mt-2 lg:mt-0 text-center lg:text-start">
              {t("card.remainingOffer", { count: selectedOffer?.usage_limit })}
            </p>
          </div>
          <div className="border-2 rounded-xl p-2 mb-2">
            <h1 className="font-bold border-b-2 mb-2">
              {t("card.offerContent")}
            </h1>
            <div className="flex flex-wrap">
              {selectedOffer?.details.map((detail, index) => (
                <div
                  key={detail.id}
                  className={`pr-[5%] ${index === 0 ? "" : "pl-[5%]"} ${
                    index === selectedOffer?.details.length - 1
                      ? ""
                      : "border-r-2 border-black/30"
                  }`}
                >
                  <p>{detail?.product_item}</p>
                  <p>
                    {detail?.quantity} {t("card.pieces")}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="border-2 rounded-xl p-2 flex justify-between">
            <div className="flex gap-5">
              <span>
                <p>{t("card.totalOffer")}</p>
                <h1>
                  {calculatePrice(
                    selectedOffer?.total_price,
                    selectedOffer?.taxes
                  )}{" "}
                  {currencyFormat()}
                </h1>
              </span>
              {selectedOffer?.taxes > 0 && (
                <span>
                  <p>{t("taxes")}</p>
                  <h1>
                    {selectedOffer?.taxes} {currencyFormat()}
                  </h1>
                </span>
              )}
            </div>
            <button
              onClick={handleAddToCart}
              className="rounded-md bg-primary text-white text-base font-semibold w-[152.5px] h-[47px]"
            >
              {t("card.addToCart")}
            </button>
          </div>
        </div>
        <Image
          className="cursor-pointer absolute right-[5%] bg-primary/20 p-2 w-8 rounded-full"
          onClick={handleClose}
          src="/close.svg"
          alt="close"
          width={12}
          height={12}
        />
      </div>
    </div>
  );
}
