"use client";

import { Star } from "iconsax-react";
import React, { useState } from "react";
import Popup1 from "./Popup1";
import OffersPopup from "./offersPopup";
import {
  productDetailsService,
  setFavoriteProduct,
} from "@/services/productServices";
import { useDispatch, useSelector } from "react-redux";
import { toggleFavorite } from "@/redux/slices/favoritesSlice";
import { setSelectedOffer } from "@/redux/slices/offersSlice";
import { setProduct } from "@/redux/slices/productSlice";
import { useLanguage } from "@/Utils/lanHook";
import { currencyFormat } from "@/Utils/commons";
function Card({ food, t }) {
  const dispatch = useDispatch();
  const { language } = useLanguage();
  const { favoriteProducts } = useSelector((state) => state.favorite);
  const { isAuth } = useSelector((state) => state.auth);
  const [offerPopup, setOfferPopup] = useState(false);

  const isFavorite = favoriteProducts.includes(food.id);

  const isProduct = Boolean(food.productDetails);
  const isOffer = Boolean(food.total_price);

  const price =
    isProduct && food.productDetails?.length > 0
      ? food.productDetails[0].price
      : "غير متوفر";

  const discount = isProduct
    ? food?.productDetails?.find((item) => item.is_default)?.discount ?? 0
    : 0;

  const showPopup = async () => {
    if (isProduct) {
      const product = await productDetailsService(food.id);
      dispatch(setProduct(product));
    }
    if (isOffer) {
      dispatch(setSelectedOffer(food));
      popUp();
    }
  };

  const handleFavoriteClick = async () => {
    await setFavoriteProduct({ account_product_id: food.id });
    dispatch(toggleFavorite(food.id));
  };

  const popUp = () => setOfferPopup(!offerPopup);

  return (
    <article className="w-full h-full p-2 md:p-4 md:mx-1 rounded-lg hover:bg-white hover:shadow-lg relative">
      {isAuth && isProduct && (
        <span
          onClick={handleFavoriteClick}
          className="bg-white cursor-pointer absolute left-5 top-5 p-[6px] md:p-2 rounded-full z-10"
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.465 15.6067C9.21 15.6967 8.79 15.6967 8.535 15.6067C6.36 14.8642 1.5 11.7667 1.5 6.51672C1.5 4.19922 3.3675 2.32422 5.67 2.32422C7.035 2.32422 8.2425 2.98422 9 4.00422C9.7575 2.98422 10.9725 2.32422 12.33 2.32422C14.6325 2.32422 16.5 4.19922 16.5 6.51672C16.5 11.7667 11.64 14.8642 9.465 15.6067Z"
              fill={isFavorite ? "#FF2D55" : ""}
              stroke={isFavorite ? "#FF2D55" : "#000"}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      )}

      <div
        onClick={showPopup}
        className="cursor-pointer h-24 sm:h-24 md:h-40 rounded-lg bg-cover bg-no-repeat relative"
        style={{ backgroundImage: `url(${food.image})` }}
      >
        <div
          className={`hidden md:flex flex-col items-start justify-center gap-1 absolute ${
            language === "ar" ? "right-2" : "left-2"
          } bottom-4`}
        >
          {isProduct && (
            <div className="text-base flex gap-1 px-3 py-1 text-white rounded-lg backdrop-blur-sm bg-black bg-opacity-40 font-normal leading-5">
              <Star
                className="w-[14px] h-[14px] md:w-[18px] md:h-[18px]"
                color="white"
              />
              <span>
                4.4<sup className="text-sm"> (232)</sup>
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="mt-2 flex flex-col items-start justify-start">
        {isProduct ? (
          <>
            <span className="block text-[15px] md:text-lg font-semibold leading-5">
              {food?.product?.name}
            </span>
            <p className="mb-1 text-[12px] line-clamp-2 md:text-sm text-gray-600">
              {food?.description}
            </p>
            <div className="flex items-center mt-2 w-full">
              {discount > 0 && (
                <span className="line-through mr-2">{price}</span>
              )}
              {discount > 0 && " : "}
              <span
                className={`text-primary text-[16px] md:text-xl font-semibold leading-5 ${
                  discount > 0 ? "ml-2" : ""
                }`}
              >
                {discount > 0 ? (price - discount).toFixed(2) : price}{" "}
                {currencyFormat()}
              </span>
            </div>
          </>
        ) : isOffer ? (
          <>
            <span className="block text-[15px] md:text-lg font-semibold leading-5">
              {food?.name}
            </span>
            <p className="mb-1 text-[12px] line-clamp-2 md:text-sm text-gray-600">
              {t("card.availableTo")} {food.end_date}
            </p>
            <div className="text-sm">
              <span
                className={`text-primary text-[16px] md:text-xl font-semibold leading-5`}
              >
                {food.total_price} {currencyFormat()}
              </span>
            </div>
          </>
        ) : null}
      </div>
      <Popup1 t={t} />
      {offerPopup && <OffersPopup onClose={popUp} t={t} />}
    </article>
  );
}

export default Card;
