import { buildHttpResponse } from "@/network/networkUtils";
import { setProduct } from "@/redux/slices/productSlice";
import { store } from "@/redux/store";
import { getSecureItem } from "@/Utils/secureStorage";
import { toast } from "react-toastify";

export const productDetailsService = async (productId) => {
  try {
    const response = await buildHttpResponse({
      method: "GET",
      endPoint: `product/show?id=${productId}`,
    });

    const { result } = response.data;

    if (result !== null || result !== undefined || result !== "") {
      store.dispatch(setProduct(result));
    }
  } catch (error) {
    console.error(error);
  }
};

export const setFavoriteProduct = async (data) => {
  const token = await getSecureItem("user")?.data?.token;
  console.log(token);
  console.log(data);
  if (!token) return;

  try {
    await buildHttpResponse({
      method: "POST",
      endPoint: "product/favorites/store",
      headers: { Authorization: `Bearer ${token}` },
      body: data,
    });

    toast.success("تمت العملية بنجاح");
  } catch (error) {
    console.error(error);
    toast.error(error.message);
  }
};
