"use client";

import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Add, Minus } from "iconsax-react";
import { Star } from "iconsax-react";
import { useDispatch, useSelector } from "react-redux";
import { clearProduct } from "@/redux/slices/productSlice";
import { addToCart } from "@/redux/slices/cartSlice";
import Image from "next/image";
import { useRouter, useSearchParams } from "next/navigation";
import { currencyFormat } from "@/Utils/commons";
import { useLanguage } from "@/Utils/lanHook";

function Popup1({ t }) {
  const { language } = useLanguage();
  const queries = useSearchParams();
  const { selectedProduct } = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const { showPopup } = useSelector((state) => state.product);
  const [productDetails, setProductDetails] = useState(null);
  const [openOptions, setOpenOptions] = useState({});
  const [optionExtrasData, setOptionExtrasData] = useState([]);
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [price, setPrice] = useState(0);
  const [isOptionOpen, setIsOptionOpen] = useState(true);
  const router = useRouter();

  useEffect(() => {
    if (selectedProduct && productDetails === null) {
      setProductDetails(selectedProduct);
      console.log(selectedProduct);
    }
  }, [productDetails, selectedProduct]);

  useEffect(() => {
    if (productDetails && selectedDetail === null) {
      const defaultDetail =
        productDetails?.productDetails > 0
          ? productDetails.productDetails?.find(
              (detail) => detail.is_default === 1
            )
          : productDetails.productDetails[0];

      if (defaultDetail) {
        setSelectedDetail(defaultDetail);
        setPrice(parseFloat(defaultDetail.price));
      }
    }
  }, [productDetails, selectedDetail]);

  const toggleDropdown = (optionId) => {
    setOpenOptions((prevOpenOptions) => ({
      ...prevOpenOptions,
      [optionId]: !prevOpenOptions[optionId],
    }));
  };

  const handleAdd = (extra) => {
    setOptionExtrasData((prevData) => {
      const existingExtra = prevData.find((data) => data.id === extra.id);
      if (existingExtra) {
        return prevData.map((data) =>
          data.id === extra.id
            ? {
                ...data,
                counts: data.counts + 1,
                totals: data.totals + parseFloat(extra.price),
              }
            : data
        );
      } else {
        return [
          ...prevData,
          {
            id: extra.id,
            price: parseFloat(extra.price),
            counts: 1,
            totals: parseFloat(extra.price),
          },
        ];
      }
    });
  };

  const handleSubtract = (extra) => {
    setOptionExtrasData((prevData) => {
      const existingExtra = prevData.find((data) => data.id === extra.id);
      if (existingExtra && existingExtra.counts > 0) {
        return prevData.map((data) =>
          data.id === extra.id
            ? {
                ...data,
                counts: data.counts - 1,
                totals: data.totals - parseFloat(extra.price),
              }
            : data
        );
      }
      return prevData;
    });
  };

  const getCounts = (extraId) => {
    const extra = optionExtrasData.find((data) => data.id === extraId);
    return extra ? extra.counts : 0;
  };

  const handleOptionChange = (detail) => {
    setSelectedDetail(detail);
    setPrice(parseFloat(detail.price));
  };
  const handleAddToCart = () => {
    if (typeof window !== "undefined") {
      const discount = parseFloat(selectedDetail?.discount || 0);
      const tax = parseFloat(selectedDetail?.taxes || 0);
      const basePrice = parseFloat(selectedDetail?.price || 0);
      const priceAfterDiscount = basePrice - discount;

      // Calculate the total taxes for the product
      const productTax = tax;

      // Calculate the total for option extras without taxes and their taxes
      const optionExtrasTotalWithoutTaxes = optionExtrasData.reduce(
        (total, item) => total + item.totals,
        0
      );
      const optionExtrasTaxes = optionExtrasData.reduce((total, item) => {
        const extraTax =
          productDetails?.options
            .flatMap((option) => option.optionExtras)
            .find((optExtra) => optExtra.id === item.id)?.taxes || 0;
        return total + extraTax * item.counts;
      }, 0);

      // Calculate the total product without taxes and total taxes
      const totalProductWithoutTaxes =
        priceAfterDiscount + optionExtrasTotalWithoutTaxes;
      const totalProductTaxes = productTax + optionExtrasTaxes;

      const totalPrice = totalProductWithoutTaxes + totalProductTaxes;

      const orderDetailsOptions = {
        order_details: [
          {
            account_product_detail_id: selectedDetail?.id,
            offer_id: null,
            image: productDetails?.image,
            name: productDetails?.product?.name,
            quantity: 1,
            unit_price: basePrice,
            quantity_total: totalPrice,
            unit_quantity_total: totalPrice,
            total_product_without_taxes: totalProductWithoutTaxes.toFixed(2),
            total_product_taxes: totalProductTaxes.toFixed(2),
            order_details_options: optionExtrasData.map((extra) => {
              const option = productDetails?.options.find((opt) =>
                opt.optionExtras.some((optExtra) => optExtra.id === extra.id)
              );
              const extraName = option?.optionExtras.find(
                (optExtra) => optExtra.id === extra.id
              )?.name;
              const extraTax =
                productDetails?.options
                  .flatMap((opt) => opt.optionExtras)
                  .find((optExtra) => optExtra.id === extra.id)?.taxes || 0;

              return {
                option_extra_id: extra.id,
                option_extra_name: extraName,
                unit_price: extra.price,
                quantity: extra.counts,
                quantity_total: (
                  extra.totals +
                  extraTax * extra.counts
                ).toFixed(2),
              };
            }),
          },
        ],
      };

      dispatch(addToCart(orderDetailsOptions));
      console.log(orderDetailsOptions);

      handlePopUpClose();
    }
  };

  const orderNow = () => {
    handleAddToCart();
    router.push(`/order?${queries}`);
  };

  const [isHovered, setIsHovered] = useState(false);
  const [hoverPosition, setHoverPosition] = useState({ x: 0.5, y: 0.5 });

  const handleMouseEnter = (x, y) => {
    setIsHovered(true);
    setHoverPosition({ x, y });
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const calculatePosition = (x, y) => {
    const xPos = x === 0 ? -0.25 : x === 1 ? 0.25 : 0;
    const yPos = y === 0 ? -0.25 : y === 1 ? 0.25 : 0;
    return { x: xPos, y: yPos };
  };

  const { x, y } = calculatePosition(hoverPosition.x, hoverPosition.y);

  if (!showPopup || !productDetails) return null;

  // دالة لفتح الخيار
  const openOption = () => {
    setIsOptionOpen(true);
  };

  // دالة لإغلاق الخيار
  const closeOption = () => {
    setIsOptionOpen(false);
  };

  // Toggle function to handle open/close
  const toggleOptionn = () => {
    isOptionOpen ? closeOption() : openOption();
  };

  const handlePopUpClose = () => {
    dispatch(clearProduct());
    setProductDetails(null);
    setSelectedDetail(null);
    setOptionExtrasData([]);
    setOpenOptions(false);
    setPrice(0);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75 z-50"
    >
      <div className="bg-white rounded-lg shadow-lg m-12 w-[1073px] h-fit relative">
        <span
          onClick={handlePopUpClose}
          className="absolute top-[-5px] right-[-45px] cursor-pointer"
        >
          <Image src="/Frameclose.svg" alt="close" width={40} height={40} />
        </span>

        <div className="px-4 grid grid-cols-1 md:grid-cols-2  space-x-4 ">
          <div className=" pt-4 rounded-tl-[16px]">
            <div
              className="relative rounded-2xl h-[400px] mb-4 overflow-hidden"
              onMouseLeave={handleMouseLeave}
            >
              <motion.div
                className="absolute inset-0 bg-cover bg-center"
                style={{ backgroundImage: `url(${productDetails?.image})` }}
                animate={{
                  scale: isHovered ? 1.5 : 1,
                  x: isHovered ? x * 100 + "%" : "0%",
                  y: isHovered ? y * 100 + "%" : "0%",
                  transition: { duration: 0.3 },
                }}
              />
              <div
                className="absolute top-0 left-0 w-1/2 h-1/2"
                onMouseEnter={() => handleMouseEnter(1, 1)}
              />
              <div
                className="absolute top-0 right-0 w-1/2 h-1/2"
                onMouseEnter={() => handleMouseEnter(0, 1)}
              />
              <div
                className="absolute bottom-0 left-0 w-1/2 h-1/2"
                onMouseEnter={() => handleMouseEnter(1, 0)}
              />
              <div
                className="absolute bottom-0 right-0 w-1/2 h-1/2"
                onMouseEnter={() => handleMouseEnter(0, 0)}
              />
            </div>
          </div>

          <div className="flex flex-col justify-between py-4 h-[531px]">
            <div className="flex flex-col overflow-y-auto scrollbar-hide">
              <h2 className="text-lg font-bold">
                {productDetails?.product?.name}{" "}
              </h2>
              <p className="text-gray-600 text-[14px] font-normal w-[85%] leading-[18px]">
                {productDetails?.description}
              </p>
              <div className="my-2">
                <span className="text-primary text-[20px] font-semibold leading-[24px]">
                  {price} {currencyFormat()}{" "}
                </span>
                <div className="flex gap-2">
                  <div className="flex justify-center items-center w-fit gap-1 px-2 py-1 mt-2 text-black rounded-md bg-[#F2F2F2]">
                    <Star size="15" color="black" />
                    <span className="text-[13px]">
                      4.4<sup className="text-[11px]"> (200)</sup>
                    </span>
                  </div>
                  <div className="flex justify-center items-center w-fit px-2 py-1 mt-2 text-black rounded-md bg-[#F2F2F2]">
                    <span className="text-[13px]">20%</span>
                  </div>
                </div>
                <div className="w-1/1 mx-auto border-t border-[#DCDCDC] mb-[3px] mt-3"></div>
              </div>

              <div className="mt-3 p-2 relative box-border border border-gray-300 rounded-lg">
                <div
                  className="flex items-center justify-between"
                  onClick={toggleOptionn}
                >
                  <div className="flex flex-col">
                    <span className="text-black text-[16px] font-bold leading-[120.3%]">
                      {t("card.chose")}
                    </span>
                    <span className="text-[14px] text-[#9D9D9D] mt-1 font-medium leading-[120.3%]">
                      {t("card.chose")}
                    </span>
                  </div>
                  <motion.span
                    className="text-2xl transition-transform duration-100 bg-[#E6E6E6] flex items-center justify-center text-black w-[27px] h-[27px] cursor-pointer rounded-full"
                    initial={{ rotate: 0 }}
                  >
                    {isOptionOpen ? "-" : "+"}
                  </motion.span>
                </div>

                {/* Animation for opening/closing the extra options */}
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{
                    height: isOptionOpen ? "auto" : 0,
                    opacity: isOptionOpen ? 1 : 0,
                  }}
                  transition={{ duration: 0.2 }} // زمن الانتقال 0.2 ثانية
                  className="overflow-hidden"
                >
                  <div className="mt-4">
                    <div className="w-[80%] mx-auto border-t border-[#DCDCDC] h-full"></div>

                    {/* خيارات راديو للاختيار */}

                    {productDetails?.productDetails.map((detail) => (
                      <div
                        key={detail.id}
                        className="flex justify-between items-center py-2"
                      >
                        <label className="flex w-full justify-between cursor-pointer">
                          <div className="flex flex-col">
                            <span className="text-black text-[16px] leading-[120.3%]">
                              {detail.item}
                            </span>
                            <span className="text-sm text-[#9D9D9D] mt-1 font-medium leading-[120.3%]">
                              {t("price")}: {detail.price} {currencyFormat()}{" "}
                              {detail.taxes > 0 &&
                                `+ tax: ${detail.taxes} ${currencyFormat()}`}
                            </span>
                          </div>
                          <input
                            type="radio"
                            name="productDetail"
                            value={detail}
                            checked={selectedDetail === detail}
                            onChange={() => handleOptionChange(detail)}
                          />
                        </label>
                      </div>
                    ))}
                  </div>
                </motion.div>
              </div>

              {productDetails?.options.map((option) => (
                <div
                  key={option.id}
                  className="mt-3 p-2 relative box-border border border-gray-300 rounded-lg"
                >
                  <div
                    onClick={() => toggleDropdown(option.id)}
                    className="flex items-center justify-between"
                  >
                    <div className="flex flex-col">
                      <span className="text-black text-[16px] font-bold leading-[120.3%]">
                        {option.name}
                      </span>
                      <span className="text-[14px] text-[#9D9D9D] mt-1 font-medium leading-[120.3%]">
                        {t("card.chose")}
                      </span>
                    </div>
                    <span className="bg-[#E6E6E6] text-black w-[27px] h-[27px] cursor-pointer rounded-full">
                      <div className="relative w-4 h-4">
                        <motion.div
                          className={`absolute top-3 ${
                            language === "ar" ? "right-[5px]" : "left-[5px]"
                          } w-full h-0.5 bg-black`}
                          initial={{ rotate: 0 }}
                          animate={{ rotate: openOptions[option.id] ? 0 : 0 }}
                          transition={{ duration: 0.5 }}
                        />
                        <motion.div
                          className={`absolute top-[5px] ${
                            language === "ar" ? "right-3" : "left-3"
                          } w-0.5 h-full bg-black`}
                          initial={{ opacity: 1 }}
                          animate={{ opacity: openOptions[option.id] ? 0 : 1 }}
                          transition={{ duration: 0.5 }}
                        />
                      </div>
                    </span>
                  </div>
                  <AnimatePresence>
                    {openOptions[option.id] && (
                      <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        {option.optionExtras.map((extra) => (
                          <div key={extra.id} className="mt-2">
                            <div className="w-[80%] mx-auto border-t border-[#DCDCDC] h-full"></div>
                            <div className="flex flex-col gap-2">
                              <div className="flex items-center justify-between py-2">
                                <div className="flex flex-col">
                                  <span className="text-black text-[16px] leading-[120.3%]">
                                    {extra?.name}
                                  </span>
                                  <span className="text-sm text-[#9D9D9D] mt-1 font-medium leading-[120.3%]">
                                    {extra?.price} {currencyFormat()}
                                  </span>
                                </div>
                                <AnimatePresence>
                                  {getCounts(extra.id) === 0 ? (
                                    <motion.button
                                      dir={language === "ar" ? "ltr" : "rtl"}
                                      onClick={() => handleAdd(extra)}
                                      initial={{ x: 100, opacity: 0 }}
                                      animate={{ x: 0, opacity: 1 }}
                                      exit={{ x: -100, opacity: 1 }}
                                      transition={{ duration: 1 }}
                                      className="flex items-center justify-center bg-[#E6E6E6] h-full p-1 rounded-full w-[65px]"
                                    >
                                      <Add size="18" color="black" />
                                      <span className="text-base text-black leading-[120%]">
                                        {t("card.add")}
                                      </span>
                                    </motion.button>
                                  ) : (
                                    <motion.div
                                      initial={{ x: 100, opacity: 0 }}
                                      animate={{ x: 0, opacity: 1 }}
                                      exit={{ x: -100, opacity: 1 }}
                                      transition={{ duration: 1 }}
                                      className="flex w-[92px] h-[32px] gap-[11px] items-center justify-center"
                                    >
                                      <button
                                        className="w-[32px] h-[32px] bg-[#DCDCDC] rounded-full border border-solid border-gray-300 p-[5px] gap-[10px]"
                                        onClick={() => handleSubtract(extra)}
                                      >
                                        <Minus size="18" color="black" />
                                      </button>
                                      <span className="text-base text-black font-normal leading-[21.6px]">
                                        {getCounts(extra.id)}
                                      </span>
                                      <button
                                        className="w-[32px] h-[32px] bg-[#DCDCDC] rounded-full border border-solid border-gray-300 p-[5px] gap-[10px]"
                                        onClick={() => handleAdd(extra)}
                                      >
                                        <Add size="18" color="black" />
                                      </button>
                                    </motion.div>
                                  )}
                                </AnimatePresence>
                              </div>
                            </div>
                          </div>
                        ))}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              ))}
            </div>

            <div className="flex items-center flex-wrap justify-between py-2 pr-2 pl-[15px] bg-white shadow rounded-xl">
              <div className="flex flex-col mb-1 items-start justify-center w-[100px]">
                <p className="text-[#9D9D9D] text-[12px] font-semibold leading-[120.3%] mb-[6px]">
                  {t("card.total")}
                </p>
                <p className="text-black text-[16px] font-semibold leading-[120.3%]">
                  {parseFloat(price + selectedDetail?.taxes) +
                    optionExtrasData.reduce(
                      (total, item) => total + item.totals,
                      0
                    )}{" "}
                  {currencyFormat()}
                </p>
              </div>
              <div className=" flex items-center gap-2">
                <button
                  onClick={orderNow}
                  className="text-primary text-base font-semibold box-border border-2 border-primary rounded-md w-[152.5px] h-[47px]"
                >
                  {t("card.orderNow")}{" "}
                </button>
                <button
                  onClick={() => handleAddToCart()}
                  className="rounded-md bg-primary text-white text-base font-semibold w-[152.5px] h-[47px]"
                >
                  {t("card.addToCart")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Popup1;
