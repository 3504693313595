import { useEffect, useState } from "react";

const getTimeLeft = (COUNTDOWN_TARGET) => {
  const totalTimeLeft = COUNTDOWN_TARGET - new Date();
  const days = Math.floor(totalTimeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor((totalTimeLeft / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((totalTimeLeft / (1000 * 60)) % 60);
  const seconds = Math.floor((totalTimeLeft / 1000) % 60);

  return { days, hours, minutes, seconds };
};

const formatTimeUnit = (unit) => {
  return unit < 10 ? `0${unit}` : unit;
};

const TimeCounter = ({ date, className, t }) => {
  const COUNTDOWN_TARGET = new Date(`${date}T23:59:59`);
  const [timeLeft, setTimeLeft] = useState(() => getTimeLeft(COUNTDOWN_TARGET));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(getTimeLeft(COUNTDOWN_TARGET));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [COUNTDOWN_TARGET]);

  const timeEntries = Object.entries(timeLeft);

  return (
    <div className={`flex justify-between ${className}`}>
      {timeEntries.map(([label, value], index) => (
        <div
          key={label}
          className={`pr-[5%] ${index === 0 ? "" : "pl-[5%]"} ${
            index === timeEntries.length - 1 ? "" : "border-r-2 border-black/30"
          }`}
        >
          <h1>{formatTimeUnit(value)}</h1>
          <h5 className="text-sm">{t(`time.${label}`)}</h5>
        </div>
      ))}
    </div>
  );
};

export default TimeCounter;
